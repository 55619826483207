<template>
    <div class="wallet">
        <van-nav-bar title="我的奶卡-使用明细" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="wallet_box">
            <div class="number">奶卡编号：{{ cycleNumber }}</div>
        </div>
        <div class="list_box">
            <div class="list">
                <div v-if="orderData.length">
                    <van-collapse accordion v-model="activeName">
                        <van-collapse-item :name="`${(index + 1)}`" v-for="(item, index) in orderData" :key="index">
                            <template #title>
                                <van-tag plain type="danger" custom-class="tag" v-if="item.status == 3">{{ item.status_label
                                }}</van-tag>
                                <van-tag plain type="success" custom-class="tag" v-else>{{ item.status_label }}</van-tag>
                                <text style="color: #ee0a24;" v-if="item.status == 3">{{ item.sended_at }}</text>
                                <text style="color: #07c160;" v-else>{{ item.sended_at }}</text>
                            </template>
                            <van-cell title="产品名称：" :value="item.product_name" />
                            <van-cell title="配送数量：" :value="item.num" />
                            <van-cell title="配送时间：" :value="item.sended_at" />
                            <van-cell v-if="item.status == 3 && item.refunded_at" title="退款时间：" :value="item.refunded_at" />
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <div v-else>
                    <van-empty description="暂无使用数据" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRoute } from "vue-router";
export default {
    setup() {
        const route = useRoute();
        const onClickLeft = () => history.back();
        const orderData = ref([]);
        const activeName = ref('1');
        const cycleNumber = ref('');
        const cycleId = ref(0);
        const loadOrderData = ()=>{
            request({
                url:'/app/user/cycle_inventory/'+cycleId.value,
                method:'GET',
                params:{
                    pageSize:100,
                }
            }).then((res)=>{
                if(res&&res.items){
                    orderData.value=res.items
                }
            })
        };
        onBeforeMount(()=>{
            cycleId.value = route.query.id;
            cycleNumber.value = route.query.number;
            if (cycleId.value) {
                loadOrderData()
            }
        });
        return{
            onClickLeft,
            orderData,
            activeName,
            cycleNumber,
        }
    },
}
</script>
<style>
.wallet_box{background-color: #07C160;padding: 25rpx;}
.wallet_box .number{color: #ffffff;font-size: 32rpx;}
.wallet_box .line{display: flex;color: #ffffff;font-size: 32rpx;margin-top: 25rpx;}
.wallet_box .line .money{flex: 1;font-size: 42rpx;}
.wallet_box .line .recharge{}

.list .tag{margin-right: 10rpx;}
</style>